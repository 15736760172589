<template>
  <div v-if="!isLoading">
    <div
      :class="['clinicCard',(clinicSelected === clinicItem.id ? 'selected' : '')]"
      v-on:click="handleRadioSelected"
    >
      <input
        type="radio"
        id="clinicList"
        name="clinicListItem"
        :value="clinicItem.id"
        v-model="picked"
        v-if="isRadio"
        v-on:change="handleRadioSelected"
      >
      <div class="main" v-if="this.clinicItem">
        <Avatar
          :image="getAvatarIcon(clinicItem.avatar)"
          size="45px"
        />
        <div class="main-data">
          <span class="text__psb clamp">{{clinicItem.name}}</span>
          <span class="text__sr clamp">{{clinicItem.email}}</span>
          <span v-if="!isPending" class="text__sr clamp">{{clinicItem.phone}}</span>
          <span
            v-if="isPending"
            class="text__psb main-data-link"
            v-on:click="handleCancel"
          >{{$i18n.t('btn_invitation_cancel')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAvatarByProps } from '@/helpers/UserHelper';
import Avatar from '@/components/atoms/Avatar/Avatar.vue';

export default {
  props: {
    clinicSelected: String,
    clinicItem: {
      type: Object,
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    invitationId: {
      type: String,
    },
  },
  data() {
    return {
      picked: this.clinicSelected || '',
    };
  },
  methods: {
    handleCancel() {
      this.$emit('handleCancel', this.invitationId);
    },
    handleRadioSelected() {
      this.picked = this.clinicItem.id;
      this.$emit('handleSelect', this.clinicItem);
    },
    getAvatarIcon(avatar) {
      return getAvatarByProps(avatar);
    },
  },
  components: {
    Avatar,
  },
};
</script>
<style lang="sass" scoped>
@import './ClinicCard.sass'
</style>
